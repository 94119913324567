var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.members,
      loading: _vm.dataLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "status",
        fn: function (text) {
          return _c(
            "div",
            {},
            [
              text === "accept"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#27ae60" },
                    },
                    [_vm._v("Active\n    ")]
                  )
                : _vm._e(),
              text === "invite"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#f39c12" },
                    },
                    [_vm._v("Invite Pending")]
                  )
                : _vm._e(),
              text === "decline"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#c0392b" },
                    },
                    [_vm._v("Decline Invite")]
                  )
                : _vm._e(),
            ],
            1
          )
        },
      },
      {
        key: "handlers",
        fn: function (text, record) {
          return _c(
            "div",
            {},
            [
              record.pivot.status === "invite"
                ? _c(
                    "a-button",
                    {
                      staticStyle: { "margin-bottom": "5px" },
                      attrs: {
                        size: "small",
                        type: "danger",
                        loading:
                          _vm.loader &&
                          _vm.selectedId === record.pivot.member_id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateTeamMember(record, "archive")
                        },
                      },
                    },
                    [_vm._v("\n      Cancel Invite\n    ")]
                  )
                : _vm._e(),
              record.pivot.status === "accept"
                ? _c(
                    "a-button",
                    {
                      staticStyle: { "margin-bottom": "5px" },
                      attrs: {
                        size: "small",
                        type: "danger",
                        loading:
                          _vm.loader &&
                          _vm.selectedId === record.pivot.member_id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateTeamMember(record, "archive")
                        },
                      },
                    },
                    [_vm._v("\n      Remove\n    ")]
                  )
                : _vm._e(),
              _c(
                "router-link",
                { attrs: { to: "/club/member/" + record.id } },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-bottom": "5px" },
                      attrs: { size: "small", type: "primary" },
                    },
                    [_vm._v("\n        View Profile\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }