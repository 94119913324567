var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { title: "Add Team Member" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: 1 } },
        [
          _c(
            "a-col",
            { attrs: { xs: 14 } },
            [
              _c("a-input", {
                attrs: { type: "email", placeholder: "Enter members email" },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 10 } },
            [
              _c(
                "a-button",
                {
                  attrs: { block: "", type: "primary" },
                  on: { click: _vm.searchEmails },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._l(_vm.members, function (member) {
            return _c(
              "a-row",
              { key: member.user_id, attrs: { type: "flex", gutter: 4 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(member.first_name) +
                      " " +
                      _vm._s(member.last_name) +
                      "..\n      "
                  ),
                ]),
                _c("a-col", { attrs: { span: 10 } }, [
                  _vm._v("\n        " + _vm._s(member.work_email) + "\n      "),
                ]),
                _c(
                  "a-col",
                  { staticClass: "gx-text-right", attrs: { span: 8 } },
                  [
                    member.teams && !member.teams.length
                      ? _c(
                          "a-button",
                          {
                            staticClass: "gx-btn-cyan",
                            attrs: {
                              block: "",
                              size: "small",
                              loading:
                                _vm.loader && _vm.selectedId === member.id,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateTeamMember(member.id, "invite")
                              },
                            },
                          },
                          [_vm._v("\n          Invite to Team\n        ")]
                        )
                      : _vm._e(),
                    member.teams && member.teams.length
                      ? _c(
                          "div",
                          [
                            member.teams[0].pivot.status === "invite"
                              ? _c(
                                  "a-button",
                                  {
                                    staticClass: "gx-btn-danger",
                                    attrs: {
                                      type: "danger",
                                      block: "",
                                      size: "small",
                                      loading:
                                        _vm.loader &&
                                        _vm.selectedId === member.id,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTeamMember(
                                          member.id,
                                          "archive"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Cancel invite\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            member.teams[0].pivot.status === "decline"
                              ? _c(
                                  "a-button",
                                  {
                                    staticClass: "gx-btn-danger",
                                    attrs: {
                                      type: "danger",
                                      block: "",
                                      size: "small",
                                      loading:
                                        _vm.loader &&
                                        _vm.selectedId === member.id,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTeamMember(
                                          member.id,
                                          "invite"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Send Invite Again\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            member.teams &&
                            member.teams.length &&
                            member.teams[0].pivot.status === "accept"
                              ? _c(
                                  "a-button",
                                  {
                                    staticClass: "gx-btn-danger",
                                    attrs: {
                                      type: "danger",
                                      block: "",
                                      size: "small",
                                      loading:
                                        _vm.loader &&
                                        _vm.selectedId === member.id,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateTeamMember(
                                          member.id,
                                          "archive"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Remove Member\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.members.length < 1 && _vm.error_msg
            ? _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { staticClass: "gx-text-right", attrs: { xs: 18 } },
                    [
                      _vm._v(
                        "\n        This Member not found in club member lists\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }